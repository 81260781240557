<template>
  <div>
    <b-row>
      <!-- Search -->
      <b-col
        cols="12"
        class="mt-2 mb-4"
      >
        <div class="d-flex align-items-center justify-content-end">
          <b-form-input
            v-model="searchValue"
            class="d-inline-block mr-1"
            placeholder="Filtrar..."
            @keyup="filtro($event, 'title', filterGlobal)"
          />
        </div>
      </b-col>
    </b-row>

    <b-row class="match-height">
      <div
        v-for="item in magazineAll"
        :key="item.id"
        style="margin: 5px;"
      >
        <a
          :href="'publicacoes/'+item.slug"
        >
          <b-card
            :img-src="getImagePath(item.thumb)"
            img-top
            :title="item.title"
            md="4"
            style="max-width: 20rem;display:block;margin: 0 auto;"
          />
        </a>
      </div>
    </b-row>
  </div>
</template>

<script>
// import { BLink } from 'bootstrap-vue'
import { mapActions } from 'vuex'

export default {
  components: {
    // BLink,
  },
  data() {
    return {
      thumb: null,
      magazineAll: [],
      searchValue: null,
      path: process.env.VUE_APP_API_BASE,

    }
  },
  computed: {
  },
  async mounted() {
    await this.getAllMagazine()
    console.log(this.$store.state.magazine.magazine)
    this.magazineAll = this.$store.state.magazine.magazine
  },
  methods: {
    getImagePath(imageName) {
      const imagePath = imageName
        ? `${this.path}/storage/magazine/thumb/${imageName}`
        : require('@/assets/default/cards/publicacaov2.jpg');

      return imagePath;
    },
    filtro(e, propriedadePesquisa, filterGlobal) {
      if (e.target.value.trim() !== '') {
        const lista = this.$store.state.magazine.magazine
        const result = filterGlobal(e, lista, propriedadePesquisa)
        this.magazineAll = result
      } else {
        this.magazineAll = this.$store.state.magazine.magazine
      }
    },
    ...mapActions('magazine', [
      'ActionListAllMagazine',
    ]),
    async getAllMagazine() {
      await this.ActionListAllMagazine()
    }
  },
}
</script>
